<template>
  <v-col cols="12" class="px-0">
    <v-row dense align="center" justify="center">
      <v-col
        cols="12"
        xl="3"
        lg="4"
        md="5"
        sm="6"
        align-self="center"
        class="px-0"
      >
        <v-card class="text-center login-page pa-3">
          <div>
            <router-link
              to="/login"
              class="d-flex flex-column align-center text-decoration-none"
            >
              <img
                src="../../../assets/logo.svg"
                alt=""
                height="60"
                class="mx-2 mb-2"
              />
              <span class="font-weight-bold text--primary mb-3">
                Anmeldung
              </span>
            </router-link>
          </div>
          <v-form ref="form" @submit.prevent="submit">
            <v-text-field
              v-model="username"
              required
              autofocus
              outlined
              dense
              :error="wronglogin"
            >
              <template v-slot:label>
                <span class="text--primary">Benutzername</span>
              </template>
            </v-text-field>

            <v-text-field
              v-model="password"
              type="password"
              required
              outlined
              dense
              :error="wronglogin"
              ><template v-slot:label>
                <span class="text--primary">Passwort</span>
              </template></v-text-field
            >
            <small v-if="wronglogin" class="error--text">Falscher Benutzername oder Passwort!</small>
            <v-btn block color="primary" type="submit" class="mt-3">
              <v-icon left>mdi-login-variant</v-icon> Login
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  data: () => ({
    username: "",
    password: "",
    wronglogin: false,
  }),
  methods: {
    async submit() {
      const response = await fetch("/api/auth/login", {
        body: JSON.stringify({
          username: this.username,
          password: this.password,
        }),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        this.wronglogin = false;
        localStorage.setItem("loggedIn", "true");
        localStorage.setItem("user", this.username);
        if (this.$route.params.nextUrl) {
          await this.$router.push(this.$route.params.nextUrl);
        } else {
          await this.$router.push("/wws/dashboard/overview");
        }
      } else {
        this.wronglogin = true;
        localStorage.removeItem("loggedIn");
        localStorage.removeItem("user");
      }
    },
  },
};
</script>
